// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");
require("chosen-js");
require("jquery-treegrid/js/jquery.treegrid.min.js");
window.bootstrap = require("bootstrap");
import 'expose-loader?bootbox!bootbox';
require("expose-loader?toastr!toastr");
// PLUGINS
require("plugins/flatpickr.js.erb");
// SRC
require("src/simple_calendar.js");
require("src/master_detail.js")
require("src/pagy.js.erb")
require("src/various.js");
// CSS
require("stylesheets/application.scss");
require.context('../images', true)
