document.addEventListener("turbolinks:load", () => {
  pagy_elements = document.querySelectorAll(".pagination");
  pagy_elements.forEach((p) => {
    p.classList.add("pagination-sm");
    p.classList.add("text-uppercase");
    p.classList.add("fw-bold");
  });
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl, { html: true, boundary: "window", trigger: "hover" })
  })
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl)
  })
  $(".tree-expanded").treegrid({
    expanderExpandedClass: "put_chevron_up",
    expanderCollapsedClass: "put_chevorn_down",
    initialState: "expanded",
  });
  $(".tree-collapsed").treegrid({
    expanderExpandedClass: "put_chevron_up",
    expanderCollapsedClass: "put_chevron_down",
    initialState: "collapsed",
  });
  // Select
  $(".chosen-select").chosen({
    no_results_text: "Nessuna corrispondenza con:",
    allow_single_deselect: true,
    placeholder_text_multiple: " ",
    search_contains: true,
    placeholder_text_single: " ",
  });
  $(".chosen-select").on("chosen:showing_dropdown", function (event) {
    var chosen_container = $(event.target).next(".chosen-container");
    var dropdown = chosen_container.find(".chosen-drop");
    var dropdown_top = dropdown.offset().top - $(window).scrollTop();
    var dropdown_height = dropdown.height();
    var viewport_height = $(window).height();

    if (dropdown_top + dropdown_height > viewport_height) {
      chosen_container.addClass("chosen-drop-up");
    }
  });
  $(".chosen-select").on("chosen:hiding_dropdown", function (event) {
    $(event.target).next(".chosen-container").removeClass("chosen-drop-up");
  });
  window.onscroll = function () {
    if ($(this).scrollTop() > 50) {
      $("#back-to-top").fadeIn("slow");
      $("#checklist-bar").addClass("bg-light");
    } else {
      $("#back-to-top").fadeOut("slow");
      $("#checklist-bar").removeClass("bg-light");
    }
  };
  $("#back-to-top").on("click", function () {
    window.scrollTo({top: 0, behavior: 'smooth'});
  });

  $(document).on("change", "#acl_detail_class_name", function () {
    var value = $("#acl_detail_class_name option:selected").val();
    $("#acl_detail_field_name").empty();
    $.ajax({
      url: "/acl_details/ajax_field_names",
      type: "GET",
      data: "&class_name=" + value,
    });
  });

  $(document).on("change", "[id$=city_id]", function () {
    var city_id = $("[id$=city_id]").val();
    $.ajax({
      url: "/zip_codes/ajax_zip_codes_for_city",
      type: "GET",
      data: "&city_id=" + city_id,
    });
  });

  $(document).on("click", ".reset_search_form", function () {
    resetForm($("#search_form"));
    $("select").val("").trigger("chosen:updated");
  });

  $(document).on("click", ".btn, .pagination", function (e) {
    e.stopPropagation();
  });

  $(document).ajaxError(function(e, error) {
    switch(error.status) {
      case 401: {
        // unauthorised (possible timeout)
        location.reload();
        break;
      }
      case 404: {
        // unauthorised (possible timeout)
        location.reload();
        break;
      }
      case 406: {
        // unauthorised (possible timeout)
        location.reload();
        break;
      }
    }
  });
});

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/service-worker.js").then((reg) => {
      console.log("Service worker registered.", reg);
    });
  });
}

window.search = function (string) {
  window.find(string, false, false, true, false, false, false);
}

$(document).on("click", "#theme-icon", function(e) {
  $.ajax({
    url: "/change_theme",
    type: "GET"
  });
});
// Reset form for filter
window.resetForm = function ($form) {
  $form
    .find(
      "input[type=number], input:text, input:password, input:file, select, textarea, input.flatpickr-input"
    )
    .val("");
  $form
    .find("input:radio, input:checkbox")
    .removeAttr("checked")
    .removeAttr("selected");
}

// Show ON on filters-button if filter is active
$(function () {
  var fields = $("#search_form").serializeArray();
  var is_blank = true;
  for (var i = 0; i < fields.length; i++) {
    if (fields[i].name != "locale" && fields[i].name != "utf8") {
      if (fields[i].value) {
        is_blank = false;
      }
    }
  }
  if (is_blank) {
    //$('#filters-button').append(': OFF');
  } else {
    $("#filters-button").append(": ON");
  }
});

// JSON pretty
window.prettyPrint = function(obj) {
  var ugly_json = JSON.parse(obj.val());
  var pretty_json = JSON.stringify(ugly_json, undefined, 2);
  obj.val(pretty_json);
  enlargeTextarea(obj);
}

// Enlarge TextArea
window.enlargeTextarea = function(obj) {
  obj[0].setAttribute(
    "style",
    "height:" + obj[0].scrollHeight + "px;overflow-y:hidden;"
  );
  obj.on("input", function () {
    obj[0].style.height = "auto";
    obj[0].style.height = obj[0].scrollHeight + "px";
  });
}

// Get parameter from url
window.getParameterByName = function(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
  results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}
